'use strict';

import DataTransport from "@/helpers/data-transport";

(async function() {
    const transport = new DataTransport();
    const url = new URL(window.location.href);

    const gameServerId = url.searchParams.get('gameServerId');
    const logFileSelect = document.querySelector('[data-role="log-file-select"]');

    logFileSelect.addEventListener('change', LogFileSelect);

    if (logFileSelect) {
        await transport.GetRawResponse(`/filemanager?gameServerId=${gameServerId}&exec=listLogFiles`)
            .then(async (data) => {
                if (data.data) {
                    data.data.forEach(row => {
                        row.Files.forEach(file => {

                            const newOption = document.createElement('option');
                            // Add custom data attributes
                            newOption.setAttribute('data-path', row.Folder);
                            newOption.setAttribute('data-filter', file.Name);
                            newOption.textContent = file.Name; // Displayed text
                            newOption.data

                            logFileSelect.appendChild(newOption);
                            //console.log(row);
                        })
                    })
                }
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });
    }

    async function LogFileSelect(event) {
        // Get the selected option
        const selectedOption = event.target.options[event.target.selectedIndex];
        const logFileContents = document.querySelector('[data-role="log-file-contents"]');
        const LogAppContents = document.querySelector('[data-role="log-app-contents"]');

        // Clear our contents
        logFileContents.innerHTML = "";
        logFileContents.placeholder = "Loading content...";

        // Access the data attributes
        const dataPath = selectedOption.dataset.path;
        const dataFilter = selectedOption.dataset.filter;

        if (!dataFilter) {
            logFileContents.classList.add("d-none");
            LogAppContents.classList.remove("d-none");
            return;
        } else {
            logFileContents.classList.remove("d-none");
            LogAppContents.classList.add("d-none");
        }

        console.log('Selected data-path:', dataPath);
        console.log('Selected data-filter:', dataFilter);


        await transport.GetRawResponse(`/filemanager?gameServerId=${gameServerId}&exec=getFile&path=${dataPath}${dataFilter}`)
            .then(async (data) => {
                console.log(data);
                if (data.data) {
                    if (data.data.length > 0) {
                        if (data.data[0].Contents) {
                            logFileContents.innerHTML = data.data[0].Contents;
                            return;
                        }
                    }
                }
                if (data.error) {
                    logFileContents.placeholder = data.message;
                } else {
                    logFileContents.placeholder = "Unable to load contents of the file...";
                }

            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });
    }
})();