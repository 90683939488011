'use strict';

import ServerStatus from "@/sections/gameservers/helper/server-status";

(function() {
    let packData = null;
    try {
        packData = packObject;
    } catch (e) {
        console.error("packObject is not defined");
        return;
    }
    const serverStatus = new ServerStatus(packData.gameServerId, packData.showLog);
})();