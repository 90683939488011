'use strict';

import DataTransport from "@/helpers/data-transport";
import AlertMessage from "@/helpers/alert-message";

(function() {
    const transport = new DataTransport();
    const alertMessage = new AlertMessage();

    const branchName = document.querySelector('[data-role="branch-name"]');
    const addBranchForm = document.querySelector('[data-role="add-branch-form"]');
    const addBranchBtn = document.querySelector('[data-role="add-branch-btn"]');

    const urlParams = new URLSearchParams(window.location.search);
    const gameId = urlParams.get('gameId') || 0;

    // For branchname to be a-z
    branchName.addEventListener('keyup', () => {
        // Regex setName so that it can only be a-zA-Z and space
        branchName.value = branchName.value.replace(/[^a-z\s]/g, "").trim();
    });

    addBranchForm.addEventListener('submit', async function(e) {
        e.preventDefault();

        // Create a FormData object to capture all form inputs
        const formData = new FormData(this);
/*
        // For debugging or customization purposes, log the form data as key-value pairs
        for (let [key, value] of formData.entries()) {
            console.log(key, value);
        }
        return;
*/

        alertMessage.SetActiveAlert("branch-info-alert");
        alertMessage.HideAlert();
        addBranchBtn.enabled = false;

        transport.PostFormData(`/admincp/AddGameBranch?exec=add&gameId=${gameId}`, formData)
            .then(data => {
                console.log(data);

                if (data.error) {
                    addBranchBtn.enabled = true;
                    alertMessage.ShowError(data.message);
                } else {
                    window.location = `/admincp/ViewGameBranch/?gameBranchId=${data.data.gameBranchId}`;
                }
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });
    });
})();