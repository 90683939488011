'use strict';

import DataTransport from "@/helpers/data-transport";

(function() {
    const transport = new DataTransport();

    const contents = document.querySelectorAll('[data-role="game-news-content"]');
    contents.forEach(async (e) => {
        const gameId = e.getAttribute('data-game-id'); // Use e directly, not e.target

        // Get news for this game
        await transport.GetRawResponse(`/news?exec=list&gameId=${gameId}`)
            .then(async data => {
                if (typeof data === 'object') {

                } else {
                    // Raw Text;
                    e.innerHTML = data;
                    //console.log(data);
                }
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });
    });
})();