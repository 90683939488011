'use strict';

import DataTransport from '@/helpers/data-transport';
import AlertMessage from "@/helpers/alert-message";
import Sanitizer from "@/helpers/sanitizer";

(function() {
    let packData = null;
    try {
        packData = packObject;
    } catch (e) {
        console.error("packObject is not defined");
        return;
    }

    const transport = new DataTransport();
    const sanitizer = new Sanitizer();
    const alertMessage = new AlertMessage();
    const createServerForm = document.querySelector('[data-role="create-server-form"]')
    const createServerBtn = document.querySelector('[data-role="create-server-button"]');
    const createServerBtnText = createServerBtn.innerHTML; // This is the default text as we might want to revert later.

    createServerForm.addEventListener('submit', async function (e) {
        e.preventDefault();

        // Create a FormData object to capture all form inputs
        const form = e.target;
        const formData = new FormData(this);

        // For debugging or customization purposes, log the form data as key-value pairs
        for (let [name, value] of formData.entries()) {
            // Get the input element by name
            const inputElement = form.querySelector(`[name="${name}"]`);

            // Get the data-inputId attribute value
            const inputTypeId = inputElement.getAttribute('data-inputTypeId');

            inputElement.value = sanitizer.ValidateInput(inputTypeId, value);
        }

        createServerBtn.disabled = true;
        createServerBtn.innerHTML = 'Creating Server - Please Wait <div class="spinner-border spinner-border-sm" role="status"></div>';

        const urlParams = new URLSearchParams(window.location.search);
        const gameId = urlParams.get('gameId') || 0;
        const clusterId = urlParams.get('clusterId') || 0;
        const gameBranchId = urlParams.get('gameBranchId') || 0;
        const templateSetId = urlParams.get('templateSetId') || 0;

        await transport.PostFormData(`/addserver/?gameId=${gameId}&exec=add&clusterId=${clusterId}&gameBranchId=${gameBranchId}&templateSetId=${templateSetId}`, formData)
            .then(async data => {
                console.log(data);
                if (typeof data === 'object') {
                    if (data.error) {
                        alertMessage.ShowError(data.message);
                        createServerBtn.disabled = false;
                        createServerBtn.innerHTML = createServerBtnText;
                    } else {
                        // Success so redirect.
                        if (data.data.gameServerId > 0) {
                            // Put a delay just because our expectaiton is things aren't instant
                            await new Promise(r => setTimeout(r, 5000));
                            //window.location.href = "/editserver?gameServerId=" + data.data.gameServerId; // Normal redirect
                            window.location.href = `/editserver?gameServerId=${data.data.gameServerId}&exec=aiAssistCreate&proactiveChatId=1`; // AI Proactive chat redirect.
                        }
                    }
                } else {
                    // Raw Text;
                }
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });
    });
})();

