'use strict';

import DataTransport from '@/helpers/data-transport';
import AlertMessage from "@/helpers/alert-message";
import ServerStatus from "@/sections/gameservers/helper/server-status";

(function() {
    let packData = null;
    try {
        packData = packObject;
    } catch (e) {
        console.error("packObject is not defined");
        return;
    }

    const transport = new DataTransport();
    const serverStatus = new ServerStatus(packData.gameServerId, packData.showLog);
    const alertMessage = new AlertMessage();
    const updateConfigBtn = document.querySelector('[data-role="update-config-btn"]');
    const updateConfigBtnText = updateConfigBtn.innerHTML; // This is the default text as we might want to revert later.
    const updateConfigForm = document.querySelector('[data-role="update-config-form"]');

    updateConfigForm.addEventListener('submit', async function (e) {
        e.preventDefault();

        // Create a FormData object to capture all form inputs
        const formData = new FormData(this);

        /*
            // For debugging or customization purposes, log the form data as key-value pairs
            for (let [key, value] of formData.entries()) {
                console.log(key, value);
            }
        */

        alertMessage.HideAlert();

        updateConfigBtn.disabled = true;
        updateConfigBtn.innerHTML = 'Saving - Please Wait <div class="spinner-border spinner-border-sm" role="status"></div>';


        await transport.PostFormData(`/editserver?gameServerId=${packData.gameServerId}&exec=saveAdvancedConfig&templateId=${packData.templateId}&userTemplateId=${packData.userTemplateId}`, formData)
            .then(async data => {
                console.log(data);
                if (typeof data === 'object') {
                    if (data.error) {
                        if (data.data.aiReply) {
                            alertMessage.ShowWarning(data.message);
                        } else {
                            alertMessage.ShowError(data.message);
                        }
                        updateConfigBtn.disabled = false;
                        updateConfigBtn.innerHTML = updateConfigBtnText;
                    } else {
                        window.location.href = `/editserver?gameServerId=${packData.gameServerId}&exec=editAdvancedConfig&templateId=${packData.templateId}&userTemplateId=${data.data.id}&msgCode=1001`;
                    }
                } else {
                    // Raw Text;
                }
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });

    });
})();