'use strict';

import DataTransport from '@/helpers/data-transport';
import AlertMessage from "@/helpers/alert-message";
import ServerStatus from "@/sections/gameservers/helper/server-status";

(function() {
    let packData = null;
    try {
        packData = packObject;
    } catch (e) {
        console.error("packObject is not defined");
        return;
    }

    const transport = new DataTransport();
    const alertMessage = new AlertMessage();
    const serverStatus = new ServerStatus(packData.gameServerId, packData.showLog);
    const changePlayersForm = document.querySelector('[data-role="change-players-form"]');
    const changePlayersBtn = document.getElementById('changePlayersBtn');
    const changePlayersBtnText = changePlayersBtn.innerHTML; // This is the default text as we might want to revert later.
    const MAXPLAYERS = document.getElementById('MAXPLAYERS');

    MAXPLAYERS.addEventListener('change', async function(e) {
        if (MAXPLAYERS.value == packData.currentPlayers) {
            changePlayersBtn.classList.add("disabled");
        } else {
            changePlayersBtn.classList.remove("disabled");
        }
    });

    changePlayersForm.addEventListener('submit', async function(e) {
        e.preventDefault();

        if (changePlayersBtn.classList.contains("disabled"))
            return;

        if (MAXPLAYERS.value == packData.currentPlayers)
            return;

        changePlayersBtn.disabled = true;
        changePlayersBtn.innerHTML = 'Updating - Please Wait <div class="spinner-border spinner-border-sm" role="status"></div>';


        alertMessage.HideAlert();

        // Create a FormData object to capture all form inputs
        const formData = new FormData(this);

        /*
            // For debugging or customization purposes, log the form data as key-value pairs
            for (let [key, value] of formData.entries()) {
                console.log(key, value);
            }
        */

        await transport.PostFormData(`/editserver?gameServerId=${packData.gameServerId}&exec=updatePlayers`, formData)
            .then(async data => {
                if (typeof data === 'object') {
                    console.log(data);
                    if (data.error) {
                        alertMessage.ShowError(data.message);
                        changePlayersBtn.disabled = false;
                        changePlayersBtn.innerHTML = changePlayersBtnText;
                    } else {
                        window.location.href = `EditServer?gameServerId=${packData.gameServerId}&exec=&msgCode=1003`;
                    }
                } else {
                    // Raw Text;
                    console.log(data);
                }
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });
    });
})();