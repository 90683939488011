'use strict';

import CliParserTemplateVariables from '@/helpers/cli-parser-template-variables';
import LoadingPage from "@/helpers/loading-page";
import DataTransport from '@/helpers/data-transport';
import AlertMessage from "@/helpers/alert-message";

(function() {
    let packData = null;
    try {
        packData = packObject;
    } catch (e) {
        console.error("packObject is not defined");
        return;
    }

    const cliParser = new CliParserTemplateVariables();
    const transport = new DataTransport();
    const alertMessage = new AlertMessage();
    const loadingPage = new LoadingPage();
    const cliParserForm = document.querySelector('[data-role="cli-parser-form"]');
    cliParserForm.addEventListener('keydown', e => {
        if (e.key === 'Enter' && e.target.tagName !== 'TEXTAREA') {
            e.preventDefault();
            return false;
        }
    });
    cliParserForm.addEventListener('submit', CliParserUpdate);

    if (packData.templateFields == 1) {
        cliParser.NewCliRow();
    } else {
        console.log("No need to add a new row.");
    }

    document.querySelector('[data-role="add-cli-button"]').addEventListener('click', function (e) {
        // Check if the event is from an input element
        cliParser.NewCliRow();
    });

    document.querySelector('[data-role="clear-fields-button"]').addEventListener('click', function(e) {
        cliParser.ClearRows();
    });

    document.querySelector('[data-role="get-descriptions-ai"]').addEventListener('click', function(e) {
        loadingPage.ShowLoading("Please wait");

        // Get the current URL
        const url = new URL(window.location.href);

        // Append the 'useAI=1' query parameter to the URL
        url.searchParams.set('useAI', '1');
        url.searchParams.set('tab', 'template');

        // Reload the page with the updated URL
        window.location.href = url.toString();
    });

    document.querySelectorAll('[data-role="new-cli-position"]').forEach( (e) => {
        cliParser.AttachInsertNewRowAtPosition(e);
    });

    cliParser.UpdatePositionInputs();

    async function CliParserUpdate(e) {
        e.preventDefault();

        alertMessage.HideAlert();

        const formData = new FormData(this);

        await transport.PostFormData(e.target.dataset.url, formData)
            .then(async data => {
                console.log(data);
                if (typeof data === 'object') {
                    if (data.error) {
                        alertMessage.ShowError(data.message);
                    } else {
                        // success
                        //window.location = data.data.url;
                        alertMessage.ShowSuccess("Updated!");
                    }
                } else {
                }
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });
    }
})();


