'use strict';

import DataTransport from '@/helpers/data-transport';
import AlertMessage from "@/helpers/alert-message";

const transport = new DataTransport();
const alertMessage = new AlertMessage();

document.querySelectorAll('.js-toggle-form').forEach(element => {
    element.addEventListener('click', (e) => {
        const targetForm = e.target.getAttribute('data-show-form');
        const currentForm = e.target.getAttribute('data-current-form');

        alertMessage.HideAlert();

        document.getElementById(`${currentForm}`).classList.remove('active');
        document.getElementById(`${targetForm}`).classList.add('active');
    });
});

document.getElementById('formLogin').addEventListener('submit', (e) => {
    e.preventDefault();

    let form = e.target;
    let email = form.querySelector('#floatingInputLoginEmail').value;
    let password = form.querySelector('#floatingLoginPassword').value;
    let submitButton = form.querySelector('button[type="submit"]'); // Find the submit button within this form

    // Retrieve the Turnstile token directly
    const turnstileToken = window.turnstile.getResponse('#turnstile-widget-login');
    // Check if Turnstile token is available
    if (!turnstileToken) {
        alertMessage.ShowError('Cloudflare verification is not valid.');
        return;
    }

    // Remove the alert.
    alertMessage.HideAlert();
    submitButton.disabled = true; // Disable the submit button

    transport.GetResponse('/login?exec=login', { email: email, password: password, turnstileToken: turnstileToken })
        .then(data => {
            if (data.error) {
                alertMessage.ShowError(data.message);
            } else {
                window.location.href = data.data.url;
            }
        })
        .catch(error => console.error('Failed to fetch data:', error))
        .finally(() => {
            submitButton.disabled = false;
        });
});

document.getElementById('formRegister').addEventListener('submit', (e) => {
    e.preventDefault();

    var form = e.target;
    var email = form.querySelector('#floatingInputLoginEmail').value;
    var password1 = form.querySelector('#floatingRegisterPassword1').value;
    var password2 = form.querySelector('#floatingRegisterPassword2').value;
    var submitButton = form.querySelector('button[type="submit"]'); // Find the submit button within this form

    // Retrieve the Turnstile token directly
    const turnstileToken = window.turnstile.getResponse('#turnstile-widget-register');
    // Check if Turnstile token is available
    if (!turnstileToken) {
        alertMessage.ShowError('Cloudflare verification is not valid.');
        return;
    }

    // Remove the alert.
    alertMessage.HideAlert();
    submitButton.disabled = true; // Disable the submit button


    transport.GetResponse('/register?exec=register', { email: email, password1: password1, password2: password2, turnstileToken: turnstileToken })
        .then(data => {
            if (data.error) {
                alertMessage.ShowError(data.message);
            } else {
                window.location.href = data.data.url;
            }
        })
        .catch(error => console.error('Failed to fetch data:', error))
        .finally(() => {
            submitButton.disabled = false;
        });
});