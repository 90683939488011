'use strict';

import DataTransport from '@/helpers/data-transport';
import AlertMessage from "@/helpers/alert-message";
import LoadingPage from "@/helpers/loading-page";

let packData = null;
let elements = null;
let stripe = null;
const alertMessage = new AlertMessage();
const loadingPage = new LoadingPage();
const transport = new DataTransport();
const payNowBtn = document.getElementById('pay-now');
const payNowBtnText = payNowBtn.innerHTML;
alertMessage.HideAlert();

async function initStripe() {
    try {
        try {
            packData = packObject;
        } catch (e) {
            console.error("packObject is not defined");
            return;
        }

        stripe = new Stripe(packData.stripePk);
        const newPayment = document.querySelector('div[data-role="new-payment"]');

        document.querySelectorAll('input[name="gateway"]').forEach((e) => {
           e.addEventListener('click', (evt) => {
               alertMessage.HideAlert();
               if (e.getAttribute("id") === "gateway-new") {
                   newPayment.classList.remove("d-none");
               } else {
                   newPayment.classList.add("d-none");
               }
           })
        });

        payNowBtn.addEventListener('click', handleSubmit);

        const appearance = {
            theme: 'night',
            labels: 'floating'
        };

        const elementsOptions = {
            clientSecret: packData.clientSecret,
            appearance: appearance,
        };

        elements = stripe.elements(elementsOptions);

        const paymentElementOptions = {
            layout: "tabs",
        };

        const paymentElement = elements.create("payment", paymentElementOptions);
        paymentElement.mount("#payment-element");
    } catch (error) {
        console.error("Error initializing Stripe:", error);
    }
}

async function handleSubmit(e) {
    let selectedPaymentProvider;
    selectedPaymentProvider = document.querySelector('input[name="gateway"]:checked').getAttribute("id");

    let paymentType = "setupIntent";
    if (packData.clientSecret.startsWith("pi_")) {
        paymentType = "paymentIntent";
    }

    alertMessage.HideAlert();

    payNowBtn.disabled = true;
    payNowBtn.innerHTML = 'Please Wait <div class="spinner-border spinner-border-sm" role="status"></div>';

    // This is an existing payment method the user has on file.
    // In this case we try and bill the user async
    if (selectedPaymentProvider !== "gateway-new") {
        const url = new URL(window.location);
        let queryString;
        url.searchParams.set('exec', 'completeCheckout');
        url.searchParams.set('payment_method', selectedPaymentProvider);
        url.searchParams.set('paymentType', "existingPaymentMethod");
        url.searchParams.set('redirect', "0");
        queryString = url.search;

        loadingPage.ShowLoading("Processing... Please wait");
        await PostCheckout(queryString);
    } else { // New payment method

        // Trigger form validation and wallet collection
        const {error: submitError} = await elements.submit();
        if (submitError) {
            payNowBtn.disabled = false;
            payNowBtn.innerHTML = payNowBtnText;
            alertMessage.ShowError(submitError.message);
            loadingPage.HideLoading();
            return;
        }

        loadingPage.ShowLoading("Processing... Please wait");

        const url = new URL(window.location);
        let queryString;
        url.searchParams.set('exec', 'completeCheckout');
        url.searchParams.set('redirect', "1");
        url.searchParams.set('paymentType', paymentType);
        queryString = url.search;


        if (packData.clientSecret.startsWith("seti_")) {
            // Confirm the SetupIntent with the card details entered by the user
            await stripe.confirmSetup({
                elements,
                clientSecret: packData.clientSecret, // From your server
                redirect: "if_required", // Ideally we will not redirect the user, so the flow feels more responsive.
                confirmParams: {
                    return_url: window.location.origin + "/checkout/" + queryString
                }
            }).then(async function (result) {
                    if (result.error) {
                        payNowBtn.disabled = false;
                        payNowBtn.innerHTML = payNowBtnText;
                        alertMessage.ShowError(result.error.message);
                        loadingPage.HideLoading();
                    } else {
                        url.searchParams.set('setup_intent', result.setupIntent.id);
                        url.searchParams.set('redirect', "0");
                        queryString = url.search;

                        await PostCheckout(queryString);
                    }
                });
        } else if (packData.clientSecret.startsWith("pi_")) {
            // Handle PaymentIntent
            await stripe.confirmPayment({
                elements,
                clientSecret: packData.clientSecret,
                redirect: "if_required",
                confirmParams: {
                    return_url: window.location.origin + "/checkout/" + queryString
                }
            }).then(async function(result) {
                if (result.error) {
                    payNowBtn.disabled = false;
                    payNowBtn.innerHTML = payNowBtnText;
                    alertMessage.ShowError(result.error.message);
                    loadingPage.HideLoading();
                } else {
                    url.searchParams.set('payment_intent', result.paymentIntent.id);
                    url.searchParams.set('redirect', "0");
                    queryString = url.search;

                    await PostCheckout(queryString);
                }
            });
        } else {
            console.error("Unknown Intent Type");
        }
    }
}

async function PostCheckout(queryString) {
    await transport.PostFormData('/checkout' + queryString)
        .then(async data => {
            if (typeof data === 'object') {
                if (data.error) {
                    alertMessage.ShowError(data.message);
                    loadingPage.HideLoading();

                    payNowBtn.disabled = false;
                    payNowBtn.innerHTML = payNowBtnText;
                } else {
                    //alertMessage.ShowSuccess("Payment successful! Redirecting you, please wait...");
                    loadingPage.ShowLoading("Payment successful! Redirecting you now...");

                    await new Promise(r => setTimeout(r, 2000));

                    window.location.href = data.data.url;
                }
            } else {
                // Raw Text;
                console.log(data);
            }
        })
        .catch(error => console.error('Failed to fetch data:', error))
        .finally(() => {

        });
}

initStripe();