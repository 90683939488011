'use strict';

import DataTransport from "@/helpers/data-transport";
import AlertMessage from "@/helpers/alert-message";

(function() {
    const transport = new DataTransport();
    const alertMessage = new AlertMessage();

    const setNameInput = document.querySelector('[data-role="set-name"]');
    const setDescriptionTextArea = document.querySelector('[data-role="set-description"]');
    const updateSetBtn = document.querySelector('[data-role="update-set-btn"]');

    const urlParams = new URLSearchParams(window.location.search);
    const templateSetId = urlParams.get('templateSetId') || 0;

    updateSetBtn.addEventListener('click', (e) => {
        UpdateSet();
    });

    function UpdateSet() {
        alertMessage.SetActiveAlert("set-info-alert");
        alertMessage.HideAlert();
        updateSetBtn.enabled = false;

        // Regex setName so that it can only be a-zA-Z and space
        setNameInput.value = setNameInput.value.replace(/[^a-zA-Z0-9-:\s]/g, "").trim();

        transport.GetRawResponse(`/admincp/UpdateTemplateSet?templateSetId=${templateSetId}`, { setName: setNameInput.value, setDescription: setDescriptionTextArea.value })
            .then(data => {
                console.log(data);
                updateSetBtn.enabled = true;
                if (data.error) {
                    alertMessage.ShowError(data.message);
                } else {
                    alertMessage.ShowSuccess(data.message);
                }
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });
    }
})();