'use strict';

import DataTransport from '@/helpers/data-transport';
import AlertMessage from "@/helpers/alert-message";
import ServerStatus from "@/sections/gameservers/helper/server-status";
import Sanitizer from "@/helpers/sanitizer";

(function() {
    let packData = null;
    try {
        packData = packObject;
    } catch (e) {
        console.error("packObject is not defined");
        return;
    }

    const transport = new DataTransport();
    const sanitizer = new Sanitizer();
    const serverStatus = new ServerStatus(packData.gameServerId, packData.showLog);
    const alertMessage = new AlertMessage();
    const updateServerBtn = document.getElementById('updateServerBtn');
    const updateServerBtnText = document.getElementById('updateServerBtn').innerHTML; // This is the default text as we might want to revert later.

    document.getElementById('updateServerForm').addEventListener('submit', async function (e) {
        e.preventDefault();

        // Create a FormData object to capture all form inputs
        const form = e.target;
        const formData = new FormData(this);

        alertMessage.HideAlert();

        // For debugging or customization purposes, log the form data as key-value pairs
        for (let [name, value] of formData.entries()) {
            // Get the input element by name
            const inputElement = form.querySelector(`[name="${name}"]`);

            // Get the data-inputId attribute value
            const inputTypeId = inputElement.getAttribute('data-inputTypeId');

            inputElement.value = sanitizer.ValidateInput(inputTypeId, value);
        }

        updateServerBtn.disabled = true;
        updateServerBtn.innerHTML = 'Updating Server - Please Wait <div class="spinner-border spinner-border-sm" role="status"></div>';

        await transport.PostFormData(`/editserver?exec=update&gameServerId=${packData.gameServerId}`, formData)
            .then(async data => {
                console.log(data);
                if (typeof data === 'object') {
                    if (data.error) {
                        alertMessage.ShowError(data.message.replace(/\n/g, '<br/>'));
                        updateServerBtn.disabled = false;
                        updateServerBtn.innerHTML = updateServerBtnText;
                    } else {
                        // Success so redirect.
                        window.location.href = `/editserver?gameServerId=${packData.gameServerId}&msgCode=1001&exec=edit`;
                    }
                } else {
                    // Raw Text;
                }
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });
    });
})();