'use strict';

import DataTransport from '@/helpers/data-transport';
import AlertMessage from "@/helpers/alert-message";
import rangeSlider from 'rangeslider-pure';

(function() {
    let packData = null;
    try {
        packData = packObject;
    } catch (e) {
        console.error("packObject is not defined");
        return;
    }

    const alertMessage = new AlertMessage();
    const transport = new DataTransport();

    // Initialize variables and elements
    const playersSlider = document.getElementById('playerSlider');
    const playerSliderContent = document.querySelectorAll('[data-role="player-slider-content"]');
    const daysSlider = document.getElementById('daysSlider');
    const daysSliderOutput = document.querySelectorAll('[data-role="days-slider-output"]');
    const locationSelect = document.getElementById('locationSelect');
    const locationContent = document.getElementById('locationContent');
    const configureForm = document.getElementById('configureForm');
    const configureFormBtn = document.getElementById('configureFormBtn');
    const configureFormBtnText = configureFormBtn.innerHTML;
    const priceSubTotal = document.querySelectorAll('[data-role="price-sub-total"]');
    const priceRecurring = document.querySelectorAll('[data-role="price-recurring"]');
    const priceToPayContent = document.querySelectorAll('.priceToPayContent');
    const newPlanDays = document.querySelector('[data-role="cluster-new-plan-days"]');
    const newPlanPrice = document.querySelector('[data-role="new-plan-price"]');
    const additionalPlayers = document.querySelectorAll('[data-role="additional-players"]');
    // Discount code
    const discountCodeBtn = document.querySelector('[data-role="discount-code-button"]');
    let discountCode;
    let discountCodePlaceholder;
    let discountCodeBtnText;
    if (discountCodeBtn) {
        discountCodeBtnText = discountCodeBtn.innerHTML;
        discountCode = document.querySelector('[data-role="discount-code"]');
        discountCodePlaceholder = discountCode.placeholder;
    }
    let discountPercentage = 0;
    const discountContainers = document.querySelectorAll('[data-role="discount-container"]');

    // Set the max value to however many pricing periods we have
    function configureDaysSlider() {
        daysSlider.max = packData.ppp.length - 1;
    }
    configureDaysSlider();


    // Update calculations based on slider values
    function updateCalculations(updateUrl) {
        // Day Slider
        const daySliderValue = daysSlider.value;
        daysSliderOutput.forEach((element) => {
            element.innerText = packData.days[daySliderValue];
        });
        if (updateUrl) updateUrlParameter('days', packData.days[daySliderValue]);

        // Player Slider
        if (playersSlider.value < packData.clusterCurrentUsedPlayers)
            playersSlider.value = packData.clusterCurrentUsedPlayers;
        const playerSliderValue = playersSlider.value;
        playerSliderContent.forEach((element) => {
            element.innerText = playerSliderValue;
        });
        if (updateUrl) updateUrlParameter('players', playerSliderValue);

        // Location Dropdown
        const locationValue = locationSelect.value;
        if (locationContent)
            locationContent.innerHTML = locationSelect[locationSelect.selectedIndex].text;
        if (updateUrl)
            updateUrlParameter('location', locationValue);

        // Get our base price before credit or discount.
        let price = packData.isCluster ? calculateReconfiguredPrice() : calculatePrice();
        let recurringPrice = price;
        // Sub total before further modification. Sub total should be prior to credit or discount.
        priceSubTotal.forEach(el => {
            el.innerHTML = price;
        });
        // Display our recurring price - this price would include discount IF it was a recurring discount.
        // We currently do not support recurring discounts.
        priceRecurring.forEach((el) => {
            el.innerHTML = price;
        });


        // Adjust price for valid discount
        if (discountPercentage > 0 && price > 0) {
            const saving = (price * discountPercentage);
            price = (price - saving).toFixed(2);
            if (discountContainers.length > 0) {
                discountContainers.forEach((element) => {
                    element.classList.remove('d-none');
                    element.querySelector('[data-role="discount-saving"]').innerText = saving.toFixed(2);
                });
            }
        }


        // Adjust for credit
        price = Math.max(0, price - packData.credit).toFixed(2);

        // Update our price to pay.
        if (priceToPayContent) {
            priceToPayContent.forEach((el) => {
                el.innerHTML = price;
            })
        }

        // When existing cluster, we must edit the "New Plan"
        if (packData.isCluster) {
            // Days can only ever get bigger.
            const newDays = Math.max(packData.clusterDays, packData.days[daySliderValue]);
            if (newPlanDays)
                newPlanDays.innerHTML = newDays;
            const newPrice = ((packData.ppp[daysSlider.value] * playersSlider.value) * newDays).toFixed(2);
            if (newPlanPrice)
                newPlanPrice.innerHTML = newPrice;
            if (additionalPlayers) {
                additionalPlayers.forEach((element) => {
                    element.innerText = Math.max(0, playersSlider.value - packData.clusterMaxPlayers);
                });
            }
        }
    }

    // Calculate price for NEW servers
    function calculatePrice() {
        const pricePerPlayer = packData.ppp[daysSlider.value];
        const players = playersSlider.value;
        const numberOfDays = packData.days[daysSlider.value];

        const price = (pricePerPlayer * players) * numberOfDays;
        return price.toFixed(2);
    }

    // Calculate price for reconfiguring EXISTING servers
    function calculateReconfiguredPrice() {
        const pricePerPlayer = packData.ppp[daysSlider.value];
        const players = playersSlider.value;
        const additionalPlayers = Math.max(players - packData.clusterMaxPlayers, 0);
        const numberOfDays = packData.days[daysSlider.value];

        let price = 0;
        // First thing we do is check if there is any additional players, and if so, we would take our PPP and * our additional players by remainingtime
        if (additionalPlayers > 0 && packData.clusterRemainingDays > 0) {
            price += (pricePerPlayer * additionalPlayers) * packData.clusterRemainingDays;
        }
        // Next update we need to see if they are also trying to adjust their contract length...
        if (numberOfDays > 0) {
            price += (pricePerPlayer * players) * numberOfDays;
        }

        return price.toFixed(2);
    }

    // Update URL parameters
    function updateUrlParameter(key, value) {
        const url = new URL(window.location);
        url.searchParams.set(key, value);
        history.replaceState(null, '', url);
    }

    // Check URL for predefined values and initialize sliders
    function checkURL() {
        const url = new URL(window.location);
        let daysParam = parseInt(url.searchParams.get('days')) || 30;
        const playersParam = parseInt(url.searchParams.get('players'));
        const locationParam = parseInt(url.searchParams.get('location'));

        // Days param comes in as a real value, i.e. 3 days... so we need to know where this is in the actual slider, so we convert daysParam to its true slider value
        let daysParamFound = false;
        for (let x = 0; x < packData.days.length; x++) {
            if (packData.days[x] === daysParam) {
                daysParam = x;
                daysParamFound = true;
                break;
            }
        }
        if (!daysParamFound) daysParam = 0;

        // Initialize sliders based on URL params
        daysSlider.value = daysParam >= 0 && daysParam < days.length ? daysParam : 0;
        playersSlider.value = playersParam >= 0 && playersParam < 200 ? playersParam : packData.clusterMaxPlayers ?? 0;
        locationSelect.value = locationParam && Array.from(locationSelect.options).some(option => option.value == locationParam) ? locationParam : locationSelect.options[0].value;

        updateCalculations();
    }
    // We only check URL if its a new order.
    if (!packData.isCluster)
        checkURL();

    // Event listener for the slider to update the tooltip on input
    playerSlider.addEventListener('input', () => {
        updateCalculations();
    });
    daysSlider.addEventListener('input', (e) => {
        updateCalculations();
    });

    // Handle playerSlider change event only if not in cluster mode
    if (!packData.isCluster) {
        playerSlider.addEventListener('change', () => {
            updateCalculations(true);
        });
        daysSlider.addEventListener('change', (e) => {
            updateCalculations(true);
        });
        locationSelect.addEventListener('change', () => {
            updateCalculations(true);
        });
    } else {
        locationSelect.addEventListener('change', () => {
            updateCalculations();
        });
    }

    function ClearDiscount(placeholderMsg) {
        if (discountCodeBtn) {
            discountCodeBtn.innerHTML = discountCodeBtnText;
            discountCodeBtn.disabled = false;
            discountCode.disabled = false;
            discountCode.placeholder = placeholderMsg || discountCodePlaceholder;
            discountCode.value = "";
            discountPercentage = 0;
            if (discountContainers.length > 0) {
                discountContainers.forEach((element) => {
                    element.classList.add('d-none');
                    element.querySelector('[data-role="discount-saving"]').innerText = "";
                });
            }
            updateCalculations();
        }
    }

    // Handle discount code
    if (discountCodeBtn) {
        discountCodeBtn.addEventListener('click', async function (e) {
            if (discountCodeBtn.innerHTML === "X") {
                ClearDiscount();
            } else {
                discountCodeBtn.innerHTML = '<div class="spinner-border spinner-border-sm" role="status"></div>';
                discountCodeBtn.disabled = true;
                discountCode.disabled = true;

                await new Promise(resolve => setTimeout(resolve, 1000));

                await transport.GetResponse('/api/checkout/promotion/getCode/', {code: discountCode.value})
                    .then(async data => {
                        try {
                            if (data.error) {
                                ClearDiscount("Promotion not valid");
                                return;
                            }
                            if (data.data.isValid == "1") {
                                if (data.data.percentageDiscount > 0) {
                                    // If we are a existing cluster and this discount code is not valid for existing orders we should stop.
                                    if (packData.isCluster && data.data.existingOrders == "0") {
                                        ClearDiscount("Promotion not valid with this order type");
                                        return;
                                    }
                                    if (!packData.isCluster && data.data.newOrders == "0") {
                                        ClearDiscount("Promotion not valid with this order type");
                                        return;
                                    }
                                    discountPercentage = data.data.percentageDiscount;
                                    discountCodeBtn.innerHTML = "X";
                                    discountCodeBtn.disabled = false;
                                    updateCalculations();
                                }
                            } else {
                                ClearDiscount("Promotion has expired");
                            }
                        } catch (error) {

                        }
                    });
            }
        });
    }

    // Handle form submission
    configureForm.addEventListener('submit', async function (e) {
        e.preventDefault();

        configureFormBtn.disabled = true;
        configureFormBtn.innerHTML = 'Please Wait <div class="spinner-border spinner-border-sm" role="status"></div>';

        alertMessage.HideAlert();

        const formData = new FormData(this);
        // Days needs to be adjusted because its currently the value of the slider (0,1,2,3 etc) but we actually need the number of days.
        formData.set('days', packData.days[daysSlider.value]);
        // Set any discount
        if (discountCode) {
            formData.set('discountCode', discountCode.value);
        }

        const url = new URL(window.location);
        const gameId = parseInt(url.searchParams.get('gameId'));
        const clusterId = parseInt(url.searchParams.get('clusterId'))|0;

        await transport.PostFormData(`/checkout/?exec=createOrder&gameId=${gameId}&clusterId=${clusterId}`, formData)
            .then(async data => {
                console.log(data);
                if (typeof data === 'object') {
                    console.log(data);
                    if (data.error) {
                        alertMessage.ShowError(data.message);
                        configureFormBtn.innerHTML = configureFormBtnText;
                        configureFormBtn.disabled = false;
                    } else {
                        // success
                        //
                        if (data.data.url) {
                            window.location = data.data.url;
                        } else {
                            if (data.message.length > 0) {
                                alertMessage.ShowSuccess(data.message);
                                configureFormBtn.innerHTML = configureFormBtnText;
                                configureFormBtn.disabled = false;
                            }
                        }
                    }
                } else {
                    // Raw Text;
                }
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });
    });
})();
