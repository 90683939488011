'use strict';

import AlertMessage from "@/helpers/alert-message";
import ServerStatus from "@/sections/gameservers/helper/server-status";

(function() {
    let packData = null;
    try {
        packData = packObject;
    } catch (e) {
        console.error("packObject is not defined");
        return;
    }

    const alertMessage = new AlertMessage();
    const serverStatus = new ServerStatus(packData.gameServerId, packData.showLog);

    document.getElementById('deleteServerBtn').addEventListener('click', () => {
        const deleteServerInput = document.getElementById('deleteServerInput').value.toLowerCase().trim();
        const deleteServerBtn = document.getElementById('deleteServerBtn');

        alertMessage.HideAlert();

        if (deleteServerInput != 'delete my server') {
            alertMessage.ShowError("You must enter exactly 'delete my server'");
        } else {
            deleteServerBtn.disabled = true;
            deleteServerBtn.innerHTML = "Please Wait";
            // This is a valid deletion request.
            window.location.replace(`/myservers?exec=RemoveServer&gameServerId=${packData.gameServerId}`);
        }
    });
})();