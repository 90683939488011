'use strict';

module.exports = class Ping {
    async PingWebSocket(serverUrl, numPings = 10, interval = 100) {
        return new Promise((resolve, reject) => {
            const latencies = [];
            let count = 0;
            let start = 0;
            let isConnected = false;

            const socket = new WebSocket(serverUrl);

            socket.onopen = () => {
                //console.log("🟢 WebSocket connected. Sending pings...");
                isConnected = true;
                this.SendPing(socket, () => start = performance.now());
            };

            socket.onmessage = (event) => {
                if (event.data === "pong") {
                    const latency = performance.now() - start;
                    //console.log(`📩 Ping ${count + 1}/${numPings}: ${latency.toFixed(2)}ms`);
                    latencies.push(latency);

                    if (++count < numPings && isConnected) {
                        setTimeout(() => this.SendPing(socket, () => start = performance.now()), interval);
                    } else {
                        socket.close();
                        this.FinalizeResults(latencies, resolve);
                    }
                }
            };

            socket.onerror = (error) => {
                console.error("❌ WebSocket error:", error);
                socket.close();
                reject("WebSocket connection failed.");
            };

            socket.onclose = () => {
                //console.log("❌ WebSocket closed.");
                isConnected = false;
            };
        });
    }

    SendPing(socket, updateStartTime) {
        if (socket.readyState === WebSocket.OPEN) {
            updateStartTime();
            socket.send("ping");
        } else {
            console.warn("⚠️ Attempted to send ping on a closed socket.");
        }
    }

    FinalizeResults(latencies, resolve) {
        if (latencies.length > 0) {
            const avgLatency = latencies.reduce((sum, l) => sum + l, 0) / latencies.length;
            console.log(`✅ Average Latency: ${avgLatency.toFixed(2)}ms`);
            resolve({ latency: Math.round(avgLatency) });
        } else {
            console.warn("⚠️ No successful pings.");
            resolve({ latency: -1 });
        }
    }
}
