'use strict';

import DataTransport from "@/helpers/data-transport";
import AlertMessage from "@/helpers/alert-message";

(function() {
    const transport = new DataTransport();
    const alertMessage = new AlertMessage();

    const updateBranchForm = document.querySelector('[data-role="update-branch-form"]');
    const updateBranchBtn = document.querySelector('[data-role="update-branch-btn"]');
    const directoriesContainer = document.querySelector('[data-role="directories-container"]');
    const addDirectoryBtn = document.querySelector('[data-role="add-directory-btn"]');

    const urlParams = new URLSearchParams(window.location.search);
    const gameBranchId = urlParams.get('gameBranchId') || 0;

    // Handle Add Directory button click
    if (addDirectoryBtn) {
        addDirectoryBtn.addEventListener('click', function() {
            const newDirectoryInput = document.createElement('div');
            newDirectoryInput.className = 'input-group mb-2 directory-input-group';
            newDirectoryInput.innerHTML = `
                <input type="text" class="form-control" name="directories[]" placeholder="e.g. AppData\\Local\\Test" />
                <button type="button" class="btn btn-outline-danger remove-directory-btn">Remove</button>
            `;
            directoriesContainer.appendChild(newDirectoryInput);

            // Add event listener to the new remove button
            const removeBtn = newDirectoryInput.querySelector('.remove-directory-btn');
            removeBtn.addEventListener('click', handleRemoveDirectory);
        });
    }

    // Add event listeners to existing remove buttons
    const existingRemoveButtons = document.querySelectorAll('.remove-directory-btn');
    existingRemoveButtons.forEach(button => {
        button.addEventListener('click', handleRemoveDirectory);
    });

    // Function to handle remove directory button
    function handleRemoveDirectory(e) {
        const inputGroup = e.target.closest('.directory-input-group');
        inputGroup.remove();

        // Ensure at least one directory input exists if there are none left
        if (directoriesContainer.querySelectorAll('.directory-input-group').length === 0) {
            addDirectoryBtn.click();
        }
    }

    updateBranchForm.addEventListener('submit', async function(e) {
        e.preventDefault();

        alertMessage.SetActiveAlert("branch-info-alert");
        alertMessage.HideAlert();
        updateBranchBtn.enabled = false;

        // Create a FormData object to capture all form inputs
        const formData = new FormData(this);

        transport.PostFormData(`/admincp/UpdateGameBranch?gameBranchId=${gameBranchId}`, formData)
            .then(data => {
                console.log(data);
                updateBranchBtn.enabled = true;
                if (data.error) {
                    alertMessage.ShowError(data.message);
                } else {
                    alertMessage.ShowSuccess(data.message);
                }
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });
    });
})();