'use strict';

import AlertMessage from "@/helpers/alert-message";
import ServerStatus from "@/sections/gameservers/helper/server-status";

(function() {
    let packData = null;
    try {
        packData = packObject;
    } catch (e) {
        console.error("packObject is not defined");
        return;
    }

    const alertMessage = new AlertMessage();
    const serverStatus = new ServerStatus(packData.gameServerId, packData.showLog);

    document.getElementById('reinstallServerBtn').addEventListener('click', () => {
        const reinstallServerInput = document.getElementById('reinstallServerInput').value.toLowerCase().trim();
        const reinstallServerBtn = document.getElementById('reinstallServerBtn');

        alertMessage.HideAlert();

        if (reinstallServerInput != 'reinstall my server') {
            alertMessage.ShowError("You must enter exactly 'reinstall my server'");
        } else {
            reinstallServerBtn.disabled = true;
            reinstallServerBtn.innerHTML = "Please Wait";
            // This is a valid reinstall request.
            window.location.replace(`/editserver?gameServerId=${packData.gameServerId}&exec=reinstall&confirm=true`);
        }
    });
})();