'use strict';

import Ping from "@/helpers/ping";

(function() {
   const ping = new Ping();

   const locationOptions = document.querySelectorAll('option[data-role="location-ping"]');
   if (locationOptions.length > 0) {
      let lowestPing = -1;
      locationOptions.forEach(async (optionElement) => {
         const pingUrl = optionElement.getAttribute("data-url");

         if (pingUrl && pingUrl.length > 0) {
            try {
               ping.PingWebSocket(pingUrl, 3, 100).then(e => {
                  let hasLocationParam = new URLSearchParams(window.location.search).has("location");
                  if (!hasLocationParam) {
                     if (e.latency > 0 && (lowestPing === -1 || e.latency < lowestPing)) {
                        lowestPing = e.latency;
                        optionElement.selected = true;
                     }
                  }
                  if (e.latency > 0) {
                     optionElement.innerHTML = `${optionElement.textContent} - ${e.latency}ms`;
                     console.log(`${pingUrl} ${e.latency}`);
                  }
               }).catch(e => {
                  console.error(e);
               });

            } catch (error) {
               console.error(`Error pinging ${pingUrl}:`, error);
            }
         }
      });
   }
}());