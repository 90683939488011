'use strict';

import DataTransport from '@/helpers/data-transport';
import AlertMessage from "@/helpers/alert-message";
import ServerStatus from "@/sections/gameservers/helper/server-status";

(function() {
    let packData = null;
    try {
        packData = packObject;
    } catch (e) {
        console.error("packObject is not defined");
        return;
    }

    const transport = new DataTransport();
    const alertMessage = new AlertMessage();
    const serverStatus = new ServerStatus(packData.gameServerId, packData.showLog);
    const updateTaskForm = document.querySelector('[data-role="update-task-form"]');
    const friendlyName = document.getElementById('friendlyName');
    const taskFrequency = document.getElementById('taskFrequency');
    const updateTaskBtn = document.getElementById('updateTaskBtn');
    const updateTaskBtnText = document.getElementById('updateTaskBtn').innerHTML; // This is the default text as we might want to revert later.


    taskFrequency.addEventListener('change', (e) => {
        ConfigureFrequency();
    });
    function ConfigureFrequency() {
        if (taskFrequency.value === 'weekly') {
            document.getElementById('dayOfWeekDiv').classList.remove('d-none');
        } else {
            document.getElementById('dayOfWeekDiv').classList.add('d-none');
        }
    }
    ConfigureFrequency();

    updateTaskForm.addEventListener('submit', async function (e) {
        e.preventDefault();

        const taskName = friendlyName.value.trim();
        if (taskName.length <= 0) {
            alertMessage.ShowError("You must enter a friendly name.");
            return;
        }

        alertMessage.HideAlert();

        updateTaskBtn.disabled = true;
        updateTaskBtn.innerHTML = 'Saving - Please Wait <div class="spinner-border spinner-border-sm" role="status"></div>';

        // Create a FormData object to capture all form inputs
        const formData = new FormData(this);

        /*
            // For debugging or customization purposes, log the form data as key-value pairs
            for (let [key, value] of formData.entries()) {
                console.log(key, value);
            }
        */

        await transport.PostFormData(`/editserver?gameServerId=${packData.gameServerId}&exec=updateTask&taskId=${packData.taskId}`, formData)
            .then(async data => {
                if (typeof data === 'object') {
                    //console.log(data);
                    if (data.error) {
                        alertMessage.ShowError(data.message);
                        updateTaskBtn.disabled = false;
                        updateTaskBtn.innerHTML = updateTaskBtnText;
                    } else {
                        window.location.href = `EditServer?gameServerId=${packData.gameServerId}&exec=showTasks&msgCode=1001`;
                    }
                } else {
                    // Raw Text;
                    console.log(data);
                }
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });
    });
})();